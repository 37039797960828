import React from 'react';

import LightboxProvider from './src/context/lightboxContext';
import ModalProvider from './src/context/modalContext';

import handleCookies from './src/utils/handleCookies';

import './static/fonts/fonts.css';
import './src/stylesheets/main.scss';

export const wrapRootElement = ({ element }) => (
  <ModalProvider>
    <LightboxProvider>{element}</LightboxProvider>
  </ModalProvider>
);

export const onClientEntry = () => {
  const mutinyInit = document.createElement('script');
  mutinyInit.innerHTML = `(function(){var a=window.mutiny=window.mutiny||{};if(!window.mutiny.client){a.client={_queue:{}};var b=["identify","trackConversion"];var c=[].concat(b,["defaultOptOut","optOut","optIn"]);var d=function factory(c){return function(){for(var d=arguments.length,e=new Array(d),f=0;f<d;f++){e[f]=arguments[f]}a.client._queue[c]=a.client._queue[c]||[];if(b.includes(c)){return new Promise(function(b,d){a.client._queue[c].push({args:e,resolve:b,reject:d})})}else{a.client._queue[c].push({args:e})}}};c.forEach(function(b){a.client[b]=d(b)})}})();`;
  document.head.appendChild(mutinyInit);

  const mutinyClient = document.createElement('script');
  mutinyClient.setAttribute('data-cfasync', 'false');
  mutinyClient.src =
    'https://client-registry.mutinycdn.com/personalize/client/e107609daae3e149.js';
  document.head.appendChild(mutinyClient);
};

export const onRouteUpdate = ({ prevLocation }) => {
  const internalUrls = ['www.upkeep.com', 'localhost:8000'];

  // Fallback in case `prevLocation` is null
  const referrer = document.referrer;

  // If navigating to a page on the same domain, do nothing
  if (
    internalUrls.some(loc => prevLocation?.href?.includes(loc)) ||
    internalUrls.some(loc => referrer?.includes(loc))
  ) {
    return;
  }

  handleCookies(referrer);
};
